import AliceCarousel from "react-alice-carousel";
import { FaStar } from "react-icons/fa";
import { useStateValue } from "../../StateProvider";
import { reviews } from "./Reviews.data";
import { ReviewsContainer, StarsContainer } from "./Reviews.style";
import Review from '../../assests/reviews-title-en.png'
import Commentaires from '../../assests/reviews-title-fr.png'

const Reviews = () => {

    const [{ isEnglish }, dispatch] = useStateValue();

    return (
        <ReviewsContainer>
            <img src={isEnglish ? Review : Commentaires} alt='Reviews title' />
            <StarsContainer>
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
            </StarsContainer>
            <AliceCarousel
                mouseTracking
                items={reviews}
                infinite="true"
                autoPlay="true"
                disableButtonsControls="true"
                // animationType="fadeout"
                animationDuration="2500"
                autoPlayInterval="5500"
            />
        </ReviewsContainer>
    )
}

export default Reviews;