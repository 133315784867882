import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { StateProvider } from './StateProvider';
import reducer, { INITIAL_STATE } from './Reducer';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Suspense } from 'react';


ReactDOM.render(
  <Suspense fallback="...loading">
    <BrowserRouter>
        <StateProvider initialState={INITIAL_STATE} reducer={reducer}>
            <App />
        </StateProvider>
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
