import styled from 'styled-components'
import background from './../../assests/seau-de-crabe-black-bg.jpg'
import Rope from './../../assests/Rope.png'

export const ReviewsContainer = styled.div`
    background: url(${background});
    background-size: cover;
    min-height: 400px;
    width: 100%;
    padding: 100px 2rem;
    text-align: center;
    position: relative;

    &:before {
        content: '';
        background-image: url(${Rope});
        height: 19px;
        width: 100vw;
        position: absolute;
        top: -8px;
        left: 0;
        z-index: 5;
    }

    img {
        margin-bottom: 2rem;
        width: 350px;

        @media screen and (max-width: 480px) {
            width: 250px;
        }
    }

    @media screen and (max-width: 1100px) {
        background-size: contain;
    }

`

export const StarsContainer = styled.div`
    text-align: center;

    & svg {
        color: gold;

        &:not(:first-child) {
            margin-left: 8px;
        }
    }
`

export const ReviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
export const ReviewAuthor = styled.p`
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
`


export const ReviewText = styled.p`
    color: #fff;
    font-size: 1.5rem;
    line-height: 1.3;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
`