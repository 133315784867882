import { OurStoryContainer, OurStoryWrapper, Photo, PhotoTitle, StoryText } from './Our-story.style';
import HistoryRoadMap from './../../../assests/roadmap-history.png'
import { useStateValue } from '../../../StateProvider';
import OurStoryEnTitle from './../../../assests/our-story-title-en.png'
import OurStoryFrTitle from './../../../assests/our-story-title-fr.png'
import { Helmet } from 'react-helmet';
import I18n from '../../../i18n';
import Navbar from '../../Navbar/Navbar.component';

const OurStoryPage = () => {
    const [{ isEnglish }, dispatch] = useStateValue();

    return (
        <OurStoryContainer>
            <Helmet>
                <title>Seau de Crabe - Our stroy</title>
                <meta name="title" content="Seau De Crabe - Our stroy" />
                <link rel="canonical" href="https://www.seaudecrabe.ca/our-story" />
                <meta name="description" content="The story about Seau de crabe" />
                <meta property="og:description" content="The story about Seau de crabe" />
                <meta property="og:title" content="Seau De Crabe - Our stroy" />
                <meta property="og:url" content="https://www.seaudecrabe.ca/our-story" />
                <meta name="author" content="Seau de Crabe" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content="Seau de Crabe resturants offer boiled, delicious and fresh seafood as Crabe, shrimp, lobster,and more, about us, our story" />
            </Helmet>
            <Navbar />
            <OurStoryWrapper>
                <PhotoTitle src={isEnglish ? OurStoryEnTitle : OurStoryFrTitle} alt='Our story title' />
                <StoryText>
                    <I18n t='our_story_title1' />
                </StoryText>
                <StoryText>
                    <I18n t='our_story_title2' />
                </StoryText>
                <StoryText>
                    <I18n t='our_story_title3' />
                </StoryText>
                <StoryText>
                    <I18n t='our_story_title4' />
                </StoryText>
                <StoryText>
                    <I18n t='our_story_title5' />
                </StoryText>
                <StoryText>
                    <I18n t='our_story_title6' />
                </StoryText>
            </OurStoryWrapper>
            <Photo src={HistoryRoadMap} alt="A bit of Seau de Crabe history" />
        </OurStoryContainer>
    )
}

export default OurStoryPage;