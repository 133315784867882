import styled from 'styled-components';
import Rope from './../../assests/Rope.png';
import FooterBG from './../../assests/footer-bg.jpg'

export const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 3rem calc((100vw - 1300px) / 2);
    font-weight: bold;
    background: url(${FooterBG});
    background-position: center bottom;
    position: relative;
    flex-wrap: wrap;

    &:before {
        content: '';
        background-image: url(${Rope});
        height: 19px;
        width: 100vw;
        position: absolute;
        top: -8px;
        left: 0;
        z-index: 5;
    }

    @media screen and (max-width: 980px) {
        flex-direction: column;
        text-align: center;
    }

    @media screen and (max-width: 1300px)  and (min-width: 980px) {
        padding: 3rem 2rem;
    }

    @media screen and (max-width: 480px) {
        background-size: cover;
    }

`

export const SocialMedia = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-width: 250px;
    margin-top: 1rem;
    font-weight: 500;
    color: #fff;

    @media screen and (max-width: 980px) {
        margin: 1rem auto;
        align-items: center;
    }
`

export const ContactInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    
    @media screen and (max-width: 980px) {
        align-items: center;
        margin-bottom: 2rem;
    }
`

export const ContactHeader = styled.div`
    margin-bottom: 1rem;
    font-weight: 700;
    color: #fff;
`

export const SocialLink = styled.a`
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    font-weight: 500;
    line-height: 1.5;
    color: #fff;

    & svg {
        margin-right: 8px;
    }
`
export const Instagram = styled.a`
    color: inherit;
    cursor: pointer;
    color: #fff;
    transition: all .2s ease-in-out;
    &:hover {
        color: #8a3ab9;
    }
`

export const Facebook = styled.a`
    color: inherit;
    cursor: pointer;
    color: #fff;
    transition: all .2s ease-in-out;
    &:hover {
        color: #3b5998;
    }
`

export const Tiktok = styled.a`
    color: inherit;
    cursor: pointer;
    color: #fff;
    transition: all .2s ease-in-out;
    &:hover {
        color: #EE1D52;
    }
`

export const Copyrights = styled.div`
    width: 100%;
    color: #fff;
    font-weight: 500;
    padding-top: 1rem;

    @media screen and (max-width: 480px) {
        width: 50%;
        text-align: center;
        margin: 0 auto;
        line-height: 1.5;
    }
`