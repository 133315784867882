import AliceCarousel from 'react-alice-carousel';
import { useStateValue } from '../../../StateProvider';
import { _items } from './Social-media.data';
import { HashTag, HeadTitle, SocialMediaContainer, SocialMediaTitleImg } from "./Social-media.style";
import SocialMediaEn from './../../../assests/social-media-title-en.png'
import SocialMediaFr from './../../../assests/social-media-title-fr.png'
import { Helmet } from 'react-helmet';
import I18n from '../../../i18n';
import Navbar from '../../Navbar/Navbar.component';

const responsive = {
    0: { items: 1 },
    620: { items: 2 },
    980: { items: 3 },
    1600: { items: 4 }
};

const SocialMediaPage = () => {

    const [{ isEnglish }, dispatch] = useStateValue();

    return (
        <SocialMediaContainer>
            <Helmet> 
                <title>Seau de Crabe - { isEnglish ? `Social media` : `Des médias sociaux`}</title>
                <meta name="title" content="Seau De Crabe - Social media" />
                <link rel="canonical" href="https://www.seaudecrabe.ca/social-media" />
                <meta name="description" content="Seau de Crabe offers delicious and fresh boiled seafood restaurants in Canada" />
                <meta property="og:description" content="Seau de Crabe offers delicious and fresh boiled seafood restaurants in Canada" />
                <meta property="og:title" content="Seau De Crabe - Social media" />
                <meta property="og:url" content="https://www.seaudecrabe.ca/social-media" />
                <meta name="author" content="Seau de Crabe"/>
                <meta property="og:type" content="website" />
                <meta name="keywords" content="Seau de Crabe resturants , foodie, crab, instagram, tiktok, facebook, snapchat, social, media, youtube" />
            </Helmet>
            <Navbar />
            <SocialMediaTitleImg src={isEnglish ? SocialMediaEn : SocialMediaFr} alt='Social media title' />
            <HeadTitle>
                <I18n t='social_media_title' />
            </HeadTitle>
            <HashTag>#SeauDeCrabe</HashTag>
            <AliceCarousel 
            mouseTracking 
            items={_items}
            infinite="true"
            autoPlay="true"
            disableButtonsControls="true"
            animationType="fadeout"
            animationDuration="1000"
            autoPlayInterval="2500"
            responsive={responsive}
            />
        </SocialMediaContainer>
    )
}

export default SocialMediaPage;