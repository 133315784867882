import React from "react"
import About from "../../About/About.component"
import Hero from "../../Hero/Hero.component"
import { Helmet } from "react-helmet";
import { OrderBtn, OrderLink } from "../../OrderApp/Order.style"
import OrderCart from '../../../assests/order-now-mob.png'
import Grids from "../../Grids/Grids.component";
import Reviews from "../../Reviews/Reviews.component";
import I18n from './../../../i18n'
import Navbar from "../../Navbar/Navbar.component";

const Home = () => {

    return (
    <>
        <Helmet>
            <title>Seau de Crabe - Home</title>
            <meta name="title" content="Seau De Crabe - Seafood restaurant" />
            <link rel="canonical" href="https://www.seaudecrabe.ca/" />
            <meta name="description" content="Seau de Crabe offers delicious and fresh boiled seafood restaurants in the greater Montreal, QC and Mississauga, tornoto, ON" />
            <meta property="og:description" content="Seau de Crabe offers delicious and fresh boiled seafood restaurants in the greater Montreal, QC and Mississauga, tornoto, ON" />
            <meta property="og:title" content="Seau De Crabe - Seafood restaurant" />
            <meta property="og:url" content="https://www.seaudecrabe.ca/" />
            <meta name="author" content="Seau de Crabe"/>
            <meta property="og:type" content="website" />
            <meta name="keywords" content="Seau de Crabe resturants offer boiled, delicious and fresh seafood as Crabe, shrimp, lobster,and more in Laval and Pointe-Claire in the greater Montreal, QC, Mississauga, tornoto, ON" />
        </Helmet>
        <Navbar />
        <Hero />
        <About />
        <Grids />
        <Reviews/>
            <OrderBtn>
                <OrderLink href='https://seaudecrabe.order-online.ai/#/' target="_blank">
                    <img src={OrderCart} alt="order now" title={<I18n t="order-now" />} />
                    <p>
                        <I18n t="order-now" />
                    </p>
                </OrderLink>
            </OrderBtn>
        {/* How to order steps pages if needed */}
        {/* <StepContainer>
            <StepOne />
            <StepTwo />
            <StepThree />
            <StepFour />
        </StepContainer> */}
    </>
    )
}

export default Home