import styled from "styled-components";
import { NavLink as Link } from "react-router-i18n";

export const BlankContainer = styled.div`
  height: 96px;
  background-color: #000;
`

export const Bar = styled.img`
    width: 45px;
    height: 45px;
    margin: 0 15px;
  `;

export const BarLogo = styled.img`
    width: 70px;
`

export const Nav = styled.nav`
  height: 80px;
  width: 100%;
  padding: 3rem calc((100vw - 1300px) / 2);
  background-color: rgb(0,0,0);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  z-index: 100;
  transition: all .5s ease-in-out;

  @media screen and (max-width: 1300px)  and (min-width: 480px) {
    padding: 3rem 2rem;
  }
`;

export const NavLink = styled(Link)`
  color: #fff;
  font-size: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
`;

export const Logo = styled.img`
    width: 160px;
    transition: all .3s ease;
    left: 3rem;

    @media screen and (max-width: 480px) {
    width: 120px;
    position: absolute;
    left: 1rem;
    margin-right: auto;
  }
`

export const NavIcon = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  color: #fff;

  p {
    transform: translate(-175%, 100%);
    font-weight: bold;
  }
`;

export const CartIcon = styled.a`
  margin: 0 20px;
`;

export const Country = styled.div`
    color: white;
    display: flex;
    position: absolute;
    right: 7rem;
    cursor: pointer;
`

export const CountryFlag = styled.img`
    width: 25px;
    height: 15px;
    margin-right: 5px;
`