import { BottleImg, ContactContainer, ContactHeader, ContactTitle, ContactWrapper, Form, FormWrapper, Input, InputWrapper, Label, Select, Textarea, WavesImg } from "./Contact.style"
import ContactImg from '../../../assests/contact.png'
import { useStateValue } from "../../../StateProvider";
import { sendEmail } from './EmailJs'
import MsgInBottle from '../../../assests/msg-n-bottle.png'
import { Helmet } from "react-helmet";
import I18n from "../../../i18n";
import Navbar from "../../Navbar/Navbar.component";

const Contact = ({location}) => {

    const [ { hasSent }, dispatch] = useStateValue();

    return (
        <ContactContainer>
            <Helmet>
                <title>Seau de Crabe - Contact us</title>
                <meta name="title" content="Seau De Crabe - Contact us" />
                <link rel="canonical" href="https://www.seaudecrabe.ca/contact" />
                <meta name="description" content="contact us to share your feedback about our seafood or for franchise" />
                <meta property="og:description" content="contact us to share your feedback about our seafood or for franchise" />
                <meta property="og:title" content="Seau De Crabe - Contact us" />
                <meta property="og:url" content="https://www.seaudecrabe.ca/contact" />
                <meta name="keywords" content="Seafood restaurant, Seafood, Restaurant, Ooh, Crabe, Contact, franchise, seau de crabe" />
            </Helmet>
            <Navbar />
            <ContactWrapper>
                <WavesImg />
                <BottleImg src={MsgInBottle} alt="message in the bottle" />
                <ContactHeader>
                    <ContactTitle src={ContactImg} alt="Contact section" />
                    <span>
                        <I18n t='contact_title' />
                    </span>
                </ContactHeader>
            </ContactWrapper>
            { hasSent ? <FormWrapper>
                <h2>
                    <I18n t='contact_thankyou' />
                </h2>
                </FormWrapper> : 
            <FormWrapper>
                <Form onSubmit={e => sendEmail(e, dispatch)}>
                    <InputWrapper>
                        <Label>
                            <I18n t='name' />
                        </Label>
                        <span>
                            <Input type='text' placeholder={'John'} name="name" autoComplete='off' required />
                        </span>
                    </InputWrapper>
                    <InputWrapper>
                        <Label>
                        <I18n t='email' />
                        </Label>
                        <span>
                            <Input type='email' placeholder={'name@example.com'} name="email" autoComplete='off' required />
                        </span>
                    </InputWrapper><InputWrapper>
                        <Label>
                        <I18n t='about' />
                        </Label>
                        <span>
                            <Select name="subject">
                                <option value="feedback">{I18n.getTranslation(location, 'feedback')}</option>
                                <option value="other">{I18n.getTranslation(location, 'other')}</option>
                            </Select>
                        </span>
                    </InputWrapper><InputWrapper>
                        <Label>Message :</Label>
                        <span>
                            <Textarea type='text' placeholder='Message' name="message" autoComplete='off' required />
                        </span>
                    </InputWrapper>
                    <button type='submit' className='btn'>
                        <I18n t='send' />
                    </button>
                </Form>
            </FormWrapper>
        }
        </ContactContainer>
    )
}

export default Contact