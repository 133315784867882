import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Home from './components/Pages/Home/Home.component'
import Footer from "./components/Footer/Footer.component";
import Contact from "./components/Pages/Contact/Contact.component";
import ScrollToTop from "./ScrollToTop";
import FranchisePage from "./components/Pages/Franchise/Franchise.component";
import './App.scss';
import LavalPage from "./components/Pages/Location/Laval/Laval.component";
import PointeClairePage from "./components/Pages/Location/Pointe-Claire/PointeClaire.component";
import "react-alice-carousel/lib/alice-carousel.css";
import OurStoryPage from "./components/Pages/Our-story/Our-story.component";
import SocialMediaPage from "./components/Pages/Social-media/Social-media.component";
import MississaugaPage from "./components/Pages/Location/Mississauga/Mississauga.component";
import PageNotFound from "./components/Pages/PageNotFound/PageNotFound.component";
import { useStateValue } from './StateProvider';
import { changeLanguage } from './actions';
import { useEffect } from 'react';
import MontrealPage from './components/Pages/Location/Montreal/Montreal.component';

const App = () => {

  const base = '/:locale(en|fr)?';
  const [{ isEnglish }, dispatch] = useStateValue();
  let currentLangParam = localStorage.getItem('lang');

  if (!currentLangParam && window) {
    currentLangParam = 'en';
    localStorage.setItem('lang', 'en');
  }

  useEffect(() => {

    if (window) {
      const pathArray = window.location.pathname.split('/');
      const langParam = pathArray[1];

      if (langParam === 'en') {
        dispatch(changeLanguage(true));
        localStorage.setItem('lang', 'en');
      } else if (langParam === 'fr') {
        dispatch(changeLanguage(false));
        localStorage.setItem('lang', 'fr');
      } else if (langParam !== base) {
        const pathArray = window.location.pathname.split('/');
        pathArray[0] = currentLangParam;
        console.log('pathArray here: ', '/' + pathArray.join('/'));
        window.location.href = '/' + pathArray.join('/'); // to get the whole array with the different lang param
      }
    }
  }, [isEnglish]);

  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            <Redirect to={`/${currentLangParam}`} />
          </Route>
          <Route exact path={base} component={Home} />
          <Route path={`${base}/contact`} component={Contact} />
          <Route path={`${base}/our-story`} component={OurStoryPage} />
          <Route path={`${base}/social-media`} component={SocialMediaPage} />
          <Route path={`${base}/franchise`} component={FranchisePage} />
          <Route path={`${base}/location/laval`} component={LavalPage} />
          <Route path={`${base}/location/pointe-claire`} component={PointeClairePage} />
          <Route path={`${base}/location/mississauga`} component={MississaugaPage} />
          <Route path={`${base}/location/montreal-downtown`} component={MontrealPage} />
          <Route path={`${base}/404`} component={PageNotFound} />
          <Route path="*">
            <Redirect to="/404" />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
