import styled from 'styled-components'
import Rope from './../../assests/Rope.png'
import background from './../../assests/map.jpg';
import backgroundMob from './../../assests/map-mob.jpg';

export const AboutContainer = styled.div`
    padding: 100px 100px 0;
    position: relative;
    background: url(${background});
    background-size: cover !important;
    /* background-color: #b6b2a7; */
    min-height: 600px;

    &:before {
        content: '';
        background-image: url(${Rope});
        height: 19px;
        width: 100vw;
        position: absolute;
        top: -8px;
        left: 0;
        z-index: 5;
    }

    &:after {
        content: '';
        background-image: url(${Rope});
        height: 19px;
        width: 100vw;
        position: absolute;
        bottom: -8px;
        left: 0;
        z-index: 5;
    }

    @media screen and (max-width: 1200px) {
        padding: 100px 2rem 0;
        height: auto;
        background-size: contain !important;
    }

    @media screen and (max-width: 680px) {
        background: url(${backgroundMob});
    }
`

export const Logo = styled.img`
    width: 475px;
    position: absolute;
    bottom: 0;
    right: calc((100vw - 1200px) / 2);

    @media screen and (max-width: 1200px) {
        width: 350px;
        position: relative;
        right: auto;
    }
`

export const AboutWrapper = styled.div`
    width: 50%;
    margin-right: 5%;
    padding-bottom: 5rem;

    @media only screen and (max-width:1200px) {
        width: 80%;
        margin: auto;
    }

    @media only screen and (max-width: 680px) {
        width: 100%;
        padding-bottom: 3rem;
    }
`

export const AboutText = styled.h2`
    font-size: 24px;
    line-height: 1.5;
    font-weight: 400;
    margin: 10px 0;

    @media only screen and (max-width:480px) {
        padding: 1rem 0;
    }
`

export const HeadText = styled.h1`
    font-size: 24px;
    line-height: 1.5;
    font-weight: 400;
    margin: 10px 0;

    @media only screen and (max-width:480px) {
        padding: 1rem 0;
    }
`

export const TextContainer = styled.div`
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: left;
    align-items: center;

    @media only screen and (max-width:1200px) {
        flex-direction: column;
    }
`