import { Address, BranchContainer, BranchLabel, BranchWrapper, BrunchPhoto, City, FranchiseContainer, Map, Text } from "../../Franchise/Franchise.style"
import { Helmet } from "react-helmet";
import { AppLink, AppLogo, AppsLogoWrapper } from "../../../OrderApp/Order.style";
import Uber from './../../../../assests/uber.png'
import Doordash from './../../../../assests/doordash.jpg'
import Skip from './../../../../assests/skip.png'
import SeauDeCrabe from './../../../../assests/cluster.jpg'
import Laval from './../../../../assests/laval-title.png'
import bruncheBackground from './../../../../assests/laval-branch.jpg';
import I18n from "../../../../i18n";
import Navbar from "../../../Navbar/Navbar.component";

const LavalPage = () => {

    const iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d44749.82704586288!2d-73.76692177902966!3d45.49267953109966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc923ce4bc038ef%3A0xd9a34df935adcd53!2sSeau%20de%20Crabe!5e0!3m2!1sen!2sca!4v1645244845527!5m2!1sen!2sca" width="400" height="300" style="border:0; width:100%" allowfullscreen="" loading="lazy"></iframe>'

    return (
        <FranchiseContainer>
            <Helmet>
                <title>Seau de Crabe - Laval</title>
                <meta name="title" content="Seau de Crabe - Laval" />
                <link rel="canonical" href="https://www.seaudecrabe.ca/location/laval" />
                <meta name="description" content="Seau de Crabe offers delicious and fresh seafood in Laval, QC" />
                <meta name="keywords" content="Seafood restaurant, Seafood, Restaurant, Ooh, Crabe, crab, fish, shrimp, crevette, lobster, homard, Mussels, Moules, Laval, Qeubec" />
                <meta property="og:description" content="Seau de Crabe offers delicious and fresh seafood in Laval, QC" />
                <meta property="og:title" content="Seau De Crabe - Laval" />
                <meta property="og:url" content="https://www.seaudecrabe.ca/" />
                <meta name="author" content="Seau de Crabe"/>
                <meta property="og:type" content="website" />
            </Helmet>
            <Navbar />
            <BrunchPhoto style={{'background': `url(${bruncheBackground})`}}>
                <BranchLabel src={Laval}></BranchLabel>
            </BrunchPhoto>
            <Map class="map" dangerouslySetInnerHTML={ {__html: iframe }} />
            <BranchContainer>
                <BranchWrapper> 
                    <City><I18n t='address' />:</City>
                    <Address href="https://goo.gl/maps/7oVx8e6oMmWFj4F27" target="_blank">671a Bd du Curé-Labelle, Laval, QC H7V 2T8</Address>
                    <br/>
                    <City>Contact: </City>
                    <Address href="tel:+14506873111">+ 1 (450) 687-3111</Address>
                    <br/>
                    <City><I18n t='hours' />:</City>
                    <Text><I18n t='weekdays.monday' /> 12 p.m. - 10 p.m.</Text>
                    <Text><I18n t='weekdays.tuesday' /> 12 p.m. - 10 p.m.</Text>
                    <Text><I18n t='weekdays.wednesday' /> 12 p.m. - 10 p.m.</Text>
                    <Text><I18n t='weekdays.thursday' /> 12 p.m. - 10 p.m.</Text>
                    <Text><I18n t='weekdays.friday' /> 12 p.m. - 10 p.m.</Text>
                    <Text><I18n t='weekdays.saturday' /> 12 p.m. - 10 p.m.</Text>
                    <Text><I18n t='weekdays.sunday' /> 12 p.m. - 10 p.m.</Text>
                    <City><I18n t='order-online' /></City>
                    <AppsLogoWrapper>
                        <AppLink href='https://seaudecrabe.order-online.ai/#/' target='_blank' >
                            <AppLogo src={SeauDeCrabe} alt="SeauDeCrabe cluster app logo"/>
                        </AppLink>
                        <AppLink href='https://www.doordash.com/en-CA/store/seau-de-crabe-laval-1180865/' target='_blank' >
                            <AppLogo src={Doordash} alt="Doordash icon"/>
                        </AppLink>
                        <AppLink exact href='https://www.skipthedishes.com/seau-de-crabe-inc' target='_blank' >
                            <AppLogo src={Skip} alt="Skip icon" />
                        </AppLink>
                        <AppLink
                            href='https://www.ubereats.com/ca/store/seau-de-crabe-laval/O5cf5bWxTy-aWnEqG8bqJQ'
                            target='_blank'
                        >
                            <AppLogo src={Uber} alt="Uber eats icon" />
                        </AppLink>
                    </AppsLogoWrapper>
                </BranchWrapper>
            </BranchContainer>
        </FranchiseContainer>
    )
}

export default LavalPage