import { useState } from "react";
import { Address, City, Country, CountryContainer, CountryWrapper, FormWrapper, FranchiseContainer, FranchiseTitleImg, Label, TextWrapper, WorldBrunches, TextContainer, ListText, NextCitiesContainer, NextCitiesImage, NextCitiesWrapper } from "./Franchise.style";
import FranchiseImg from '../../../assests/franchise-title.png';
import brunches from '../../../assests/seau-de-crab-map-2.png';
import { useStateValue } from '../../../StateProvider';
import { Helmet } from "react-helmet";
import { franchiseRequest } from '../Contact/EmailJs';
import { Form, Input, InputWrapper } from "../Contact/Contact.style";
import I18n from "../../../i18n";
import Navbar from "../../Navbar/Navbar.component";
import TorontoBranch from "../../../assests/toronto_branch.jpg";
import DubaiBranch from "../../../assests/dubai_branch.jpg";

const FranchisePage = ({ location }) => {

    const [{ isEnglish, hasFranchiseSent }, dispatch] = useStateValue();

    const [input, setInput] = useState('');

    function validatePostalCode(value) {

        let val = value;

        let trimmed = value.replace(/\s+/g, "");

        if (trimmed.length > 6) {
            trimmed = trimmed.substr(0, 6)
        }

        trimmed = trimmed.replace(/-/g, "");
        let postCode = [];
        postCode.push(trimmed.substr(0, 3));
        if (trimmed.substr(3, 3) !== "") postCode.push(trimmed.substr(3, 3));

        val = postCode.join(" ").toUpperCase();
        setInput(val)
    };

    return (
        <FranchiseContainer>
            <Helmet>
                <title>Seau de Crabe - Franchise</title>
                <meta name="title" content="Seau De Crabe - Franchise" />
                <link rel="canonical" href="https://www.seaudecrabe.ca/franchise" />
                <meta name="description" content="Seau de Crabe offers delicious and fresh seafood in Quebec, Canada" />
                <meta property="og:description" content="Seau de Crabe offers delicious and fresh seafood in Quebec, Canada" />
                <meta property="og:title" content="Seau De Crabe - Franchise" />
                <meta property="og:url" content="https://www.seaudecrabe.ca/franchise" />
                <meta name="author" content="Seau de Crabe" />
                <meta name="keywords" content="Seafood restaurant, Seafood, Restaurant, Ooh, Crabe, crab, fish, shrimp, crevette, lobster, homard, Mussels, Moules, Franchise, QC, Canada" />
            </Helmet>
            <Navbar />
            <FranchiseTitleImg src={FranchiseImg} alt="Franchise section" />
            <WorldBrunches src={brunches} alt="Our resturants on the world map" />
            <CountryContainer>
                <CountryWrapper>
                    <Country>Canada</Country>
                    <City>Montréal (Downtown):</City>
                    <Address href="https://maps.app.goo.gl/f5gtF56DFCQzb3pc6" target="_blank">3751 Rue Saint-Urbain, Montréal, QC H2W 1T4</Address>
                    <br />
                    <City>Laval:</City>
                    <Address href="https://goo.gl/maps/fCVHYnDjJHHh2ZT6A" target="_blank">671a Boulevard du Curé-Labelle, QC , H7V 2T8</Address>
                    <br />
                    <City>Point-Claire:</City>
                    <Address href="https://goo.gl/maps/t5AvSSSPY9d2PN8k7" target="_blank">1866 Sources Blvd, Pointe-Claire, QC , H9R 5B1</Address>
                    <br />
                    <City>Mississauga:</City>
                    <Address href="https://goo.gl/maps/4Ddr2v5BpDtyFU826" target="_blank">670 Eglinton Ave W #4, Mississauga, ON L5R 3V2</Address>
                </CountryWrapper>
                <CountryWrapper>
                    <Country>UAE</Country>
                    <City>Dubai:</City>
                    <Address href="https://goo.gl/maps/hQx24vpihks8AfCw9" target="_blank">Express kitchen, Hessa St, Barcha 3</Address>
                </CountryWrapper>
            </CountryContainer>

            <TextWrapper>
                <Country style={{ textAlign: 'center' }}></Country>
                <TextContainer style={{ flexDirection: 'column' }}>
                    <Country style={{ textAlign: 'center' }}>
                        <I18n t='franchise.title1' />
                    </Country>
                    <City>
                        <I18n t='franchise.subtitle1-1' />
                    </City>
                    <City>
                        <I18n t='franchise.subtitle1-2' />
                    </City>
                    <City>
                        <I18n t='franchise.subtitle1-3' />
                    </City>
                    <ul>
                        <ListText>
                            <I18n t='franchise.list1' />
                        </ListText>
                        <ListText>
                            <I18n t='franchise.list2' />
                        </ListText>
                        <ListText>
                            <I18n t='franchise.list3' />
                        </ListText>
                        <ListText>
                            <I18n t='franchise.list4' />
                        </ListText>
                        <ListText>
                            <I18n t='franchise.list5' />
                        </ListText>
                        <ListText>
                            <I18n t='franchise.list6' />
                        </ListText>
                        <ListText>
                            <I18n t='franchise.list7' />
                        </ListText>
                    </ul>
                </TextContainer>
            </TextWrapper>

            <TextWrapper>
                <Country style={{ textAlign: 'center' }}>
                    <I18n t='franchise.title2' />
                </Country>
                <City>
                    <I18n t='franchise.subtitle2-1' />
                </City>
                {
                    hasFranchiseSent ?
                        <FormWrapper>
                            <h2 style={{ textAlign: 'center' }}>
                                <I18n t='franchise.send_request' />
                            </h2>
                        </FormWrapper>
                        :
                        <FormWrapper>
                            <Form onSubmit={e => {
                                franchiseRequest(e, dispatch);
                                setInput('');
                            }}>
                                <InputWrapper>
                                    <Label>
                                        <I18n t='firstname' />
                                    </Label>
                                    <span>
                                        <Input type='text' placeholder={I18n.getTranslation(location, 'firstname')} name="firstname" autoComplete='off' required />
                                    </span>
                                </InputWrapper>
                                <InputWrapper>
                                    <Label>
                                        <I18n t='lastname' />
                                    </Label>
                                    <span>
                                        <Input type='text' placeholder={I18n.getTranslation(location, 'lastname')} name="lastname" autoComplete='off' required />
                                    </span>
                                </InputWrapper>
                                <InputWrapper>
                                    <Label>
                                        <I18n t='email' />
                                    </Label>
                                    <span>
                                        <Input type='text' placeholder={I18n.getTranslation(location, 'placeholders.email')} name="email" autoComplete='off' required />
                                    </span>
                                </InputWrapper>
                                <InputWrapper>
                                    <Label>
                                        <I18n t='phone' />
                                    </Label>
                                    <span>
                                        <Input type='text' placeholder={I18n.getTranslation(location, 'placeholders.phone')} name="phone"
                                            minLength="10" maxLength="10" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} autoComplete='off' required />
                                    </span>
                                </InputWrapper>
                                <InputWrapper>
                                    <Label>
                                        <I18n t='franchise.geographic' />
                                    </Label>
                                    <span>
                                        <Input type='text' placeholder={I18n.getTranslation(location, 'placeholders.postal-code')} name="geographic"
                                            pattern="[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]" value={input} onInput={e => validatePostalCode(e.target.value)}
                                            minLength="7" maxLength="7" autoComplete='off' required />
                                    </span>
                                </InputWrapper>
                                <InputWrapper>
                                    <Label>
                                        <I18n t='franchise.capital_investment' />
                                    </Label>
                                    <span>
                                        <Input type='text' placeholder={isEnglish ? `Example: 300K` : `Exemple: 300K`} name="investment"
                                            minLength="3" maxLength="6" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} autoComplete='off' required />
                                    </span>
                                </InputWrapper>

                                <button type='submit' className='btn btn-red'>
                                    <I18n t='send' />
                                </button>
                            </Form>
                        </FormWrapper>
                }

                <Country style={{ textAlign: 'center', marginTop: '3rem' }}>
                    <I18n t='franchise.our-next' />
                </Country>

                <NextCitiesContainer>
                    <NextCitiesWrapper>
                        <NextCitiesImage src={DubaiBranch} />
                        <h3>Dubai</h3>
                    </NextCitiesWrapper>
                    <NextCitiesWrapper>
                        <NextCitiesImage src={TorontoBranch} />
                        <h3>Toronto</h3>
                    </NextCitiesWrapper>
                </NextCitiesContainer>

            </TextWrapper>

        </FranchiseContainer>
    )
}

export default FranchisePage;