import { MenuNavWrapper, MenuNavList, MenuNavLink } from './MenuNav.style';
import CA from '../../assests/ca.png';
import { CountryFlag } from '../Navbar/Navbar.style';
import { useStateValue } from '../../StateProvider';
import { toggleNavMenu } from '../../actions';
import I18n from '../../i18n';

const MenuNav = () => {

    const [{ isEnglish, isMenuOpen }, dispatch] = useStateValue();
    const switchLang = isEnglish ? 'fr' : 'en'
    let currentPath = '';

    if(!!window) {
        const pathArray = window.location.pathname.split('/');

        if(pathArray.length >= 2) {
            pathArray[1] = switchLang;
            currentPath = pathArray.join('/'); // to get the whole array with the different lang param 
        }
    }

    const toggleMenu = () => {
        dispatch(toggleNavMenu(isMenuOpen));
        return isMenuOpen;
    };

    return (
        <>
            <MenuNavWrapper className={isMenuOpen ? 'openMenu' : 'closeMenu'}>
                <MenuNavList to='/' onClick={toggleMenu}><I18n t='navbar.home' /></MenuNavList>
                <MenuNavList to='/franchise' onClick={toggleMenu}>Franchise</MenuNavList>
                <MenuNavList to='/our-story' onClick={toggleMenu}><I18n t='navbar.our-story' /></MenuNavList>
                <MenuNavLink href='https://seaudecrabe.order-online.ai/#/' target="_blank" onClick={toggleMenu}><I18n t='order-now' /></MenuNavLink>
                <MenuNavLink href={`${currentPath}`} ><CountryFlag src={CA} alt="Canada flag icon" />{isEnglish ? 'FR' : 'EN'}</MenuNavLink>
                <MenuNavList to='/contact' onClick={toggleMenu}><I18n t='navbar.contact' /></MenuNavList>
            </MenuNavWrapper>
        </>
    )
}


export default MenuNav