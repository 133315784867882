import { ReviewAuthor, ReviewText, ReviewWrapper } from "./Reviews.style";

export const reviews = [
    <ReviewWrapper>
        <ReviewAuthor>
            Kimberly Divine
        </ReviewAuthor>
        <ReviewText>
        Pour une première expérience, j'ai eu un excellent service et rapide. Le personnel est magnifique. Il y avait de la nourriture en abondance. Michel, son collègue à la réception et les cuisiniers ont pris soin de nous. Le seul regret que j'ai, c'est de ne pas avoir donné un plus gros pourboire!        </ReviewText>
    </ReviewWrapper>,
    <ReviewWrapper>
        <ReviewAuthor>
            EmilyBatista
        </ReviewAuthor>
        <ReviewText>
            GET THE HOUSE FLAVOR!! Out of all places that offer seafood boils in the Montreal and Ontario region this one is definitely top.
            We ordered the 1lb snow crab, amazing!! And the 1lb mussels also incredible. What was honestly the most amazing dish we ordered was their fried shrimp. Oh my god, so good. Definitely need to try this place!! The atmosphere is not like a regular restaurant it definitely feels more like a take-out place but nonetheless the people working there are working very hard.
        </ReviewText>
    </ReviewWrapper>,
    <ReviewWrapper>
        <ReviewAuthor>
            Ashraf Fouad
        </ReviewAuthor>
        <ReviewText>
            nourriture santé très délicieuse servie d'une façon amusante; personnel et propriétaires très très accueillants et très serviables; place très propre et ambiance très conviviale. Nous avons passé un temps très précieux entre amis. Nous avons hâte d'y revenir :) Grand merci et grand bravo.
        </ReviewText>
    </ReviewWrapper>,
    <ReviewWrapper>
        <ReviewAuthor>
            Angelina Makridis
        </ReviewAuthor>
        <ReviewText>
        Food here is amazing and prices are quite fair.The staff is also very welcoming and nice. It was pretty busy when we went and the staff gave us a little appetizer to compensate for the wait,
         which we thought was very sweet! Highly recommend :)
        </ReviewText>
    </ReviewWrapper>,
    <ReviewWrapper>
        <ReviewAuthor>
            Mounim
        </ReviewAuthor>
        <ReviewText>
            1ére expérience aujourd'hui, et sans doute pas la dernière!
            Excellent service et le goût était impeccable. Je recommande fortement.
        </ReviewText>
    </ReviewWrapper>,
    <ReviewWrapper>
        <ReviewAuthor>
            Mustafa Azawy
        </ReviewAuthor>
        <ReviewText>
            I was in Montreal visiting  for 3 days and ate at the place twice. Loved the food. The sauce they use In their buckets is super tasty and flavorful. Also,
            their  dynamite shrimp Is delicious, highly recommended. Staff was funny and easy going.
        </ReviewText>
    </ReviewWrapper>,
];