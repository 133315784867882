import styled from 'styled-components'
import background from '../../../assests/map.jpg'
import backgroundMob from '../../../assests/map-mob.jpg'

export const PageNotFoundContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(${background});
    padding: 0 2rem;

    @media screen and (max-width: 680px) {
        background: url(${backgroundMob});
    }

    h3 {
        font-size: 2.5rem;
        color: red;
        margin-bottom: .5rem;
        text-align: center;
    }

    p {
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        line-height: 1.5;
        margin-bottom: .5rem;

        @media screen and (max-width: 680px) {
            font-size: 16px;
        }
    }
`

export const Logo = styled.img`
    width: 475px;
    margin-bottom: 2rem;

    @media screen and (max-width: 1200px) {
        width: 350px;
    }

    @media screen and (max-width: 680px) {
        width: 250px;
        right: auto;
    }
`