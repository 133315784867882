import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-i18n';

export const slowDown = keyframes`
    0% { opacity: 0; right: -3rem }
    100% { opacity: 1; right: 0.5rem }
`

export const MenuNavWrapper = styled.ul`
    flex-direction: column;
    align-items: center;
    list-style: none;
    background-color: rgb(0,0,0);
    border-radius: 3px;
    width: 180px;
    position: absolute;
    top: 53px;
    padding: 10px 0;
    text-shadow: 1px 1px black;
    z-index: 1;

    & a:not(:last-child),
    Link:not(:last-child) {
        border-bottom: 1px solid #535353;
    }

    @media screen and (max-width: 480px) {
        right: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0, .9);
    }
`

export const MenuNavList = styled(Link)`
    width: 100%;
    color: white;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    text-shadow: 1px 1px black;
    outline: none;
    transition: all .3s ease-in-out;

    &:hover {
        color: red;
    }

    @media screen and (max-width: 480px) {
        padding: 15px 20px;
        text-align: left;
        font-size: 16px;
    }

`
export const MenuNavLink = styled.a`
    width: 100%;
    color: white;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    text-shadow: 1px 1px black;
    outline: none;
    transition: all .3s ease-in-out;

    &:nth(last-child) {
        border-bottom: 1px solid lightgray;
    }

    &:hover {
        color: red;
    }

    @media screen and (max-width: 480px) {
        text-align: left;
        font-size: 16px;
        padding: 15px 20px;
    }
`