import { Address, BranchContainer, BranchLabel, BranchWrapper, BrunchPhoto, City, FranchiseContainer, Map, Text } from "../../Franchise/Franchise.style";
import { Helmet } from "react-helmet";
import { AppLink, AppLogo, AppsLogoWrapper } from "../../../OrderApp/Order.style";
import bruncheBackground from './../../../../assests/mississauga-branch.jpg';
import Uber from './../../../../assests/uber.png';
import Doordash from './../../../../assests/doordash.jpg';
import Skip from './../../../../assests/skip.png';
import SeauDeCrabe from './../../../../assests/cluster.jpg';
import Mississauga from './../../../../assests/mississauga-title.png';
import I18n from "../../../../i18n";
import Navbar from "../../../Navbar/Navbar.component";

const MississaugaPage = () => {

    const iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.6956330689604!2d-79.6679843!3d43.59205569999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b41949cc13725%3A0x18bd751e152004e!2sSeau%20de%20Crabe!5e0!3m2!1sen!2sca!4v1667654525738!5m2!1sen!2sca" width="600" height="450" style="border:0; width:100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'

    return (
        <FranchiseContainer>
            <Helmet>
                <title>Seau de Crabe - Mississauga</title>
                <meta name="title" content="Seau de Crabe - Mississauga" />
                <link rel="canonical" href="https://www.seaudecrabe.ca/location/mississauga" />
                <meta name="description" content="Seau de Crabe offers delicious and fresh seafood in Mississauga, ON" />
                <meta name="keywords" content="Seafood restaurant, boil, boiled, Seafood, Restaurant, Crabe, crab, fish, shrimp, crevette, lobster, homard, Mussels, Moules, Mississauga, Ontario" />
                <meta property="og:description" content="Seau de Crabe offers delicious and fresh seafood in Mississauga, ON" />
                <meta property="og:title" content="Seau De Crabe - Mississauga" />
                <meta property="og:url" content="https://www.seaudecrabe.ca/location/mississauga" />
                <meta name="author" content="Seau de Crabe"/>
                <meta property="og:type" content="website" />
            </Helmet>
            <Navbar />
            <BrunchPhoto style={{'background': `url(${bruncheBackground})`, backgroundPosition: 'center !important'}}>
                <BranchLabel src={Mississauga} alt="Mississauga branch"></BranchLabel>
            </BrunchPhoto>
            <Map class="map" dangerouslySetInnerHTML={ {__html: iframe }} />
            <BranchContainer>
                <BranchWrapper> 
                        <City><I18n t='address' />:</City>
                        <Address href="https://goo.gl/maps/TifVWktPSes8sFhX7" target="_blank">670 Eglinton Ave W #4, Mississauga, ON L5R 3V2</Address>
                        <br/>
                        <City>Contact: </City>
                        <Address href="tel:+19055070000">+ 1 (905) 507-0000</Address>
                        <br/>
                        <City><I18n t='hours' />:</City>
                        <Text><I18n t='weekdays.monday' /> 12 p.m. - 10 p.m.</Text>
                        <Text><I18n t='weekdays.tuesday' /> 12 p.m. - 10 p.m.</Text>
                        <Text><I18n t='weekdays.wednesday' /> 12 p.m. - 10 p.m.</Text>
                        <Text><I18n t='weekdays.thursday' /> 12 p.m. - 10 p.m.</Text>
                        <Text><I18n t='weekdays.friday' /> 12 p.m. - 10 p.m.</Text>
                        <Text><I18n t='weekdays.saturday' /> 12 p.m. - 10 p.m.</Text>
                        <Text><I18n t='weekdays.sunday' /> 12 p.m. - 10 p.m.</Text>
                        <City><I18n t='order-online' /></City>
                        <AppsLogoWrapper>
                            <AppLink href='https://seaudecrabe.order-online.ai/#/' target='_blank' >
                                <AppLogo src={SeauDeCrabe} alt="SeauDeCrabe cluster app logo"/>
                            </AppLink>
                            <AppLink href='https://www.doordash.com/en-CA/store/seau-de-crabe-(mississauga)-mississauga-24377107' target='_blank' >
                                <AppLogo src={Doordash} alt="Doordash icon"/>
                            </AppLink>
                            <AppLink exact href='https://www.skipthedishes.com/seau-de-crabe-eglinton' target='_blank' >
                                <AppLogo src={Skip} alt="Skip icon" />
                            </AppLink>
                            <AppLink
                                href='https://www.ubereats.com/ca/store/seau-de-crabe/yxaBIWbJXaW3egGDnxn7Rw'
                                target='_blank'
                            >
                                <AppLogo src={Uber} alt="Uber eats icon" />
                            </AppLink>
                        </AppsLogoWrapper>
                </BranchWrapper>
            </BranchContainer>
        </FranchiseContainer>
    )
}

export default MississaugaPage