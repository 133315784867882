import {
    ContactInfo,
    FooterContainer,
    Instagram,
    Facebook,
    Tiktok,
    SocialLink,
    SocialMedia,
    ContactHeader,
    Copyrights
} from "./Footer.style"
import { IconContext } from "react-icons";
import { GrInstagram } from 'react-icons/gr'
import { ImFacebook2 } from 'react-icons/im'
import { SiTiktok, SiMaildotru } from 'react-icons/si'
import I18n from "../../i18n";

const Footer = () => {

    const currentLangParam = localStorage.getItem('lang');

    return (
        <FooterContainer>
            <IconContext.Provider value={{ style: { verticalAlign: 'middle', fontSize: '24px' } }}>
                <ContactInfo>
                    <ContactHeader>
                        <I18n t='contact-us' />:
                    </ContactHeader>
                    <SocialLink href='mailto:Info@seaudecrabe.com'>
                        <SiMaildotru />Info@seaudecrabe.com
                    </SocialLink>
                    <SocialMedia>
                        <I18n t='follow-us' />:
                        <Tiktok href='https://vm.tiktok.com/ZMeuAr363/https://vm.tiktok.com/ZMeuAr363/'>
                            <SiTiktok />
                        </Tiktok>
                        <Instagram href='https://www.instagram.com/seaudecrabe/'>
                            <GrInstagram />
                        </Instagram>
                        <Facebook href='https://www.facebook.com/seaudecrabe/'>
                            <ImFacebook2 />
                        </Facebook>
                    </SocialMedia>
                </ContactInfo>
                <ContactInfo>
                    <ContactHeader>
                        <I18n t='our-locations' />:
                    </ContactHeader>
                    <SocialLink href={`/${currentLangParam}/location/montreal-downtown`}>Montréal Downtown, QC</SocialLink>
                    <SocialLink href={`/${currentLangParam}/location/laval`}>Laval, QC</SocialLink>
                    <SocialLink href={`/${currentLangParam}/location/pointe-claire`}>Point-Claire, QC</SocialLink>
                    <SocialLink href={`/${currentLangParam}/location/mississauga`}>Mississauga, ON</SocialLink>
                </ContactInfo>
                <Copyrights>
                    © 2024 Seau de crabe. <I18n t='copy-rights' />
                </Copyrights>
            </IconContext.Provider>
        </FooterContainer>
    )
}

export default Footer