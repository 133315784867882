import styled from 'styled-components'
import { Link } from 'react-router-dom'
import background from '../../../assests/map.jpg'
import backgroundMob from '../../../assests/map-mob.jpg'
import Rope from '../../../assests/Rope.png'

export const FranchiseContainer = styled.div`
    height: auto;
    background: url(${background});
    background-size: contain !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 100px;

    @media screen and (max-width: 680px) {
        background: url(${backgroundMob});
    }
`

export const SwirlImg = styled.img`
    width: 100%;
    margin-bottom: 5rem;
`

export const FranchiseTitleImg = styled.img`
    width: 350px;
    margin: 5rem 0;

    @media screen and (max-width:480px) {
        width: 250px;
    }
`

export const WorldBrunches = styled.img`
    width: 1000px;

    @media only screen and (max-width: 980px) {
        width: 100%
    }
`

export const CountryContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 800px;
    margin: 5rem;

    @media only screen and (max-width: 900px) {
        flex-direction: column;
        align-items: center;
    }

`

export const CountryWrapper = styled.div`
    width: 100%;

    @media only screen and (max-width: 900px) {
        width: 350px;

        &:first-child {
            margin-bottom: 2rem;
        }
    }

    @media only screen and (max-width: 480px) {
        width: 100%;
        padding: 0 2rem 2rem;
    }

`

export const BranchContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 425px;
    margin: 5rem;

    @media only screen and (max-width: 900px) {
        flex-direction: column;
        align-items: center;
    }
`

export const BranchWrapper = styled.div`
    width: 100%;

    @media only screen and (max-width: 900px) {
        width: 350px;
    }

    @media only screen and (max-width: 480px) {
        width: 100%;
        padding: 0 2rem 2rem;
    }

`

export const BranchCity = styled.h1`
    font-size: 72px;
    color: red;
    margin-top: 0;

    @media only screen and (max-width: 980px) {
        font-size: 48px;
    }

    @media only screen and (max-width: 480px) {
        font-size: 36px;
    }
`

export const Country = styled.h2`
    font-size: 72px;
    color: red;
    margin: 0;

    @media only screen and (max-width: 1440px) {
        font-size: 60px;
    }

    @media only screen and (max-width: 980px) {
        font-size: 48px;
    }

    @media only screen and (max-width: 480px) {
        font-size: 36px;
    }
`

export const City = styled.h3`
    font-size: 1.5rem;
    line-height: 1.5;
    margin-bottom: 1rem;
    letter-spacing: 0.8px;

    @media screen and (max-width: 480px) {
        font-size: 1rem;
        line-height: 1.6;
    }
`

export const Address = styled.a`

        text-decoration: none;
        cursor: pointer;
        color: #000;
        transition: all .2s ease-in-out;

        &:hover {
            color: red;
        }

        @media only screen and (max-width: 680px) {
            font-size: 14px;
        }

        @media only screen and (max-width: 480px) {
            font-size: 12px;
        }
`

export const Btn = styled(Link)`
    color: red;
    border: 2px solid red;
    border-radius: 10px;
    text-decoration: none;
    margin: 3rem 0;
    width: 200px;
    text-align: center;
    padding: 1rem;
    font-weight: bold;
    transition: all .4s ease-in-out;

    &:hover {
        color: white;
        background-color: red;
    }
`

export const Text = styled.p`

    font-size: 14px;

    @media only screen and (max-width: 480px) {
        font-size: 12px;
    }
`

export const BrunchPhoto = styled.div`
    width: 100%;
    height: 600px;
    background-size: cover !important;
    background-position: center !important;
    filter: brightness(.8);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 480px) {
        height: 400px;
    }
`

export const BranchLabel = styled.img`
    @media screen and (max-width: 480px) {
        width: 250px;
    }
`

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin: auto;

    h1 {
        color: #182435;
    }

    @media screen and (max-width:680px) {

        h1 {
            font-size: 18px;
        }
    }

    @media screen and (max-width:420px) {
        h1 {
            font-size: 14px
        }
    }
`

export const Map = styled.div`
    width: 100%;
    position: relative;

    & iframe {
        height: 600px;

        @media screen and (max-width: 480px) {
            height: 350px;
        }
    }

    &:before {
        content: '';
        background-image: url(${Rope});
        height: 19px;
        width: 100vw;
        position: absolute;
        top: -8px;
        left: 0;
        z-index: 5;
    }

    &:after {
        content: '';
        background-image: url(${Rope});
        height: 19px;
        width: 100vw;
        position: absolute;
        bottom: -8px;
        left: 0;
        z-index: 5;
    }
`

export const OrderBtnWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

export const TextWrapper = styled.div`
    padding-bottom: 5rem;
    width: 85%;
    margin: auto;

    @media only screen and (max-width: 680px) {
        padding-bottom: 3rem;
    }
`

export const Label = styled.label`
    white-space: nowrap;

    @media screen and (max-width: 680px) {
        white-space: normal;
    }

    @media screen and (max-width: 480px) {
        max-width: 85%;
    }
`

export const TextContainer = styled.div`
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: left;

    @media only screen and (max-width:1200px) {
        flex-direction: column;
    }
`
export const ListText = styled.li`
    font-size: 1.5rem;
    line-height: 1.5;
    margin-bottom: 1rem;
    letter-spacing: 0.8px;

    @media screen and (max-width: 768px) {
        font-size: 1rem;
    }
`

export const NextCitiesContainer = styled.div`
    display: flex;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`

export const NextCitiesWrapper = styled.div`
    background-color: #000;
    padding: 1rem 1rem 7rem;
    width: 100%;
    margin: 3rem;
    position: relative;

    @media screen and (max-width: 980px) {
        padding: 1rem 1rem 5rem;
    }

    @media screen and (max-width: 768px) {
        margin: 3rem 0;
    }

    & h3 {
        color: white;
        position: absolute;
        font-size: 3rem;
        margin: 0;
        left: 50%;
        bottom: 25px;
        transform: translate(-50%, 0);
        text-transform: uppercase;
        cursor: default;

        @media screen and (max-width: 980px) {
            font-size: 1.5rem;
        }
    }
`

export const NextCitiesImage = styled.img`
    width: 100%;
`
