import { useStateValue } from "../../StateProvider";
import { HeroContainer } from './Hero.style'

const Hero = () => {

    const [{ isTouchDevice }, dispatch] = useStateValue();

    return (
        <HeroContainer isTouchDevice={isTouchDevice} />
    )
}

export default Hero