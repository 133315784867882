import { useState } from "react";
import { PageNotFoundContainer, Logo } from "./PageNotFound.style";
import SCLogo from '../../../assests/logo-R.png';
import Navbar from "../../Navbar/Navbar.component";
import { Helmet } from "react-helmet";

const PageNotFound = () => {

  const [timeleft, setTimeLeft] = useState(5);
  const getLangFromLocal = localStorage.getItem('lang');
  let isEnglish;

  if(getLangFromLocal === "fr") {
    isEnglish = false;
  } else {
    isEnglish = true
  }

  const redirectTimer = setInterval(function(){
    if(timeleft <= 1 && !!window){
      window.location.href = '/'
      clearInterval(redirectTimer);
    }
    setTimeLeft(timeleft - 1);
  }, 1000);

    return (
      <>
        <Helmet>
          <title>Seau de Crabe - Page not found</title>
          <meta name="title" content="Seau de Crabe - Page not found" />
          <meta name="robots" content="noindex" />
          <link rel="canonical" href="https://www.seaudecrabe.ca/404" />
          <meta property="og:title" content="Seau de Crabe - Page not found" />
          <meta property="og:url" content="https://www.seaudecrabe.ca/404" />
          <meta name="author" content="Seau de Crabe"/>
          <meta property="og:type" content="website" />
        </Helmet>

        <PageNotFoundContainer>
            <Navbar />
              <Logo src={SCLogo} alt="Seau de crabe logo" />
              <h3>
                {
                  isEnglish ? 
                  'Page not found!':
                  'Page non trouvée!'
                }
              </h3>
              <p>
                {
                  isEnglish ? 
                  `We will redirect you to the home page in ${ timeleft } seconds!` :
                  `Nous vous redirigerons vers la page d'accueil dans ${ timeleft } secondes!`
                }
              </p>
              <p>
                {
                  isEnglish ?
                  'Thank you' : 'Merci'
                }
              </p>
        </PageNotFoundContainer>
      </>
    )
}

export default PageNotFound;