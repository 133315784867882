import styled from 'styled-components'
import background from '../../../assests/map.jpg'
import backgroundMob from '../../../assests/map-mob.jpg'
import Rope from '../../../assests/Rope.png'

export const OurStoryContainer = styled.div`
    height: auto;
    background: url(${background});
    background-size: cover !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 680px) {
        background: url(${backgroundMob});
        background-size: contain !important;
    }
`

export const PhotoWrapper = styled.div`
    width: 100%;
    height: 600px;
    background-size: cover !important;
    background-position: center center !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 680px) {
        height: 350px;
    }

    &:after {
        content: '';
        background-image: url(${Rope});
        height: 19px;
        width: 100vw;
        position: absolute;
        bottom: -8px;
        left: 0;
        z-index: 5;
    }
`

export const Photo = styled.img`
    width: 100%;
`

export const OurStoryWrapper = styled.div`
    padding: 50px 2rem;
    max-width: 1200px;
    margin: auto;
    text-align: center;
`

export const StoryText = styled.p`
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.8;
    text-align: left;

    @media screen and (max-width: 680px) {
        font-size: 1rem;
    }
`

export const PhotoTitle = styled.img`
    margin-bottom: 2rem;

    @media screen and (max-width: 680px) {
        width: 250px;
        margin-bottom: 1rem;
    }
`
