import emailjs from 'emailjs-com';
import { toggleHasSent, toogleFranchiseHasSent } from "../../../actions";

export const sendEmail = (e, dispatch) => {
  e.preventDefault();

  emailjs
    .sendForm(
      process.env.REACT_APP_INFO_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      e.target,
      process.env.REACT_APP_USER_ID
    )
    .then(
      (response) => {
        dispatch(toggleHasSent());

        setTimeout(function () {
          dispatch(toggleHasSent())
        }, 5500);
        console.log('SUCCESS!', response);
      },
      (error) => {
        console.log(error.text);
      }
    );
  e.target.reset();
}

export const franchiseRequest = (e, dispatch) => {
  e.preventDefault();

  emailjs
    .sendForm(
      process.env.REACT_APP_FRANCHISE_SERVICE_ID,
      process.env.REACT_APP_FRANCHISE_TEMPLATE_ID,
      e.target,
      process.env.REACT_APP_USER_ID
    )
    .then(
      (response) => {
        dispatch(toogleFranchiseHasSent());

        setTimeout(function () {
          dispatch(toogleFranchiseHasSent())
        }, 5500);
        console.log('SUCCESS!', response);
      },
      (error) => {
        console.log(error.text);
      }
    );
  e.target.reset();
}