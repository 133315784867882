import SeauDeCrabe from '../../assests/sdcb.png'
import { Logo, AboutContainer, AboutText, AboutWrapper, HeadText, TextContainer } from './About.style'
import I18n from '../../i18n';

const About = () => { 
    
    return (
    <AboutContainer>
        <TextContainer>
            <AboutWrapper>
                <HeadText>
                    <I18n t='about_header_title1'/>
                </HeadText>
                <AboutText>
                    <I18n t='about_header_title2' />
                </AboutText>
            </AboutWrapper>
            <Logo src={SeauDeCrabe} alt='Logo' />
        </TextContainer>
    </AboutContainer>
    )
}

export default About