// import { useEffect } from "react";
import { Bar, BlankContainer, Logo, Nav, NavIcon, NavLink } from "./Navbar.style";

import { toggleNavMenu, toggleMenuHidden } from "../../actions";

import { useStateValue } from "../../StateProvider";

import SCLogo from '../../assests/logo.png';
import Menu from '../../assests/menu-bar-icon.png';

import MenuNav from "../MenuNav/MenuNav.component";
import { useEffect } from "react";
import { useRef } from "react";

const Navbar = () => {
  const [{ isMenuOpen }, dispatch] = useStateValue();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          dispatch(toggleMenuHidden());
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const toggleMenu = () => {
    dispatch(toggleNavMenu(isMenuOpen));
    return isMenuOpen;
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <>
      <BlankContainer></BlankContainer>
      <Nav>
        <NavLink to="/"><Logo src={SCLogo} alt="Seau de crabe logo" /></NavLink>
        <NavIcon ref={wrapperRef}>
          <Bar className={isMenuOpen ? 'navanimation' : 'logoanimation'} src={Menu} alt="Ship wheel icon" onClick={toggleMenu} />
          <MenuNav />
        </NavIcon>
      </Nav>
    </>
  );
};

export default Navbar;
