import { createI18n } from 'react-router-i18n';
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';

const locales = ['en', 'fr'];

const translations = {
    en: translationEN,
    fr: translationFR
}

const I18n = createI18n(
    locales,
    translations,
);

export default I18n;