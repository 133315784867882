import { Address, City, FranchiseContainer, Text, BrunchPhoto, Map, BranchLabel, BranchWrapper, BranchContainer } from "../../Franchise/Franchise.style"
import { Helmet } from "react-helmet";
import bruncheBackground from './../../../../assests/reservation.jpg';
import PointClaire from './../../../../assests/pointe-claire-title.png'
import { AppLink, AppLogo, AppsLogoWrapper } from "../../../OrderApp/Order.style";
import Uber from './../../../../assests/uber.png'
import Doordash from './../../../../assests/doordash.jpg'
import Skip from './../../../../assests/skip.png'
import SeauDeCrabe from './../../../../assests/cluster.jpg'
import I18n from "../../../../i18n";
import Navbar from "../../../Navbar/Navbar.component";

const PointeClairePage = () => {

    const iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1394.268326845471!2d-73.78669751833492!3d45.465462894887295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc93d322d2647df%3A0x94bb75ba60bb2d81!2sSeau%20de%20Crabe!5e0!3m2!1sen!2sca!4v1645328524515!5m2!1sen!2sca" width="400" height="300" style="border:0; width:100%" allowfullscreen="" loading="lazy"></iframe>'

    return (
        <FranchiseContainer>
            <Helmet>
                <title>Seau de Crabe - Pointe-Claire</title>
                <meta name="title" content="Seau de Crabe - Pointe-Claire" />
                <link rel="canonical" href="https://www.seaudecrabe.ca/location/pointe-claire" />
                <meta property="og:url" content="https://www.seaudecrabe.ca/" />
                <meta name="description" content="Seau de Crabe offers delicious and fresh seafood in Pointe-Claire" />
                <meta property="og:title" content="Seau de Crabe - Pointe-Claire" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Seau de Crabe offers delicious and fresh seafood in Pointe-Claire" />
                <meta name="keywords" content="Seafood restaurant, Seafood, Restaurant, Ooh, Crabe, crab, fish, shrimp, crevette, lobster, homard, Mussels, Moules, Pointe-Claire, QC" />
            </Helmet>
            <Navbar />
            <BrunchPhoto style={{'background': `url(${bruncheBackground})`}}>
                <BranchLabel src={PointClaire}></BranchLabel>
            </BrunchPhoto>
            <Map class="map" dangerouslySetInnerHTML={ {__html: iframe }} />
            <BranchContainer>
                <BranchWrapper> 
                        <City><I18n t='address' />:</City>
                        <Address href="https://goo.gl/maps/DZv4gqDa1fzqJMrU7" target="_blank">1866 Sources Blvd, Pointe-Claire, Quebec H9R 5B1</Address>
                        <br/>
                        <City>Contact: </City>
                        <Address href="tel:+15146955000">+ 1 (514) 695-5000</Address>
                        <br/>
                        <City><I18n t='hours' />:</City>
                        <Text><I18n t='weekdays.monday' /> 12 p.m. - 10 p.m.</Text>
                        <Text><I18n t='weekdays.tuesday' /> 12 p.m. - 10 p.m.</Text>
                        <Text><I18n t='weekdays.wednesday' /> 12 p.m. - 10 p.m.</Text>
                        <Text><I18n t='weekdays.thursday' /> 12 p.m. - 10 p.m.</Text>
                        <Text><I18n t='weekdays.friday' /> 12 p.m. - 10 p.m.</Text>
                        <Text><I18n t='weekdays.saturday' /> 12 p.m. - 10 p.m.</Text>
                        <Text><I18n t='weekdays.sunday' /> 12 p.m. - 10 p.m.</Text>
                        <City><I18n t='order-online' /></City>
                        <AppsLogoWrapper>
                            <AppLink href='https://seaudecrabe.order-online.ai/#/' target='_blank' >
                                <AppLogo src={SeauDeCrabe} alt="SeauDeCrabe cluster app logo"/>
                            </AppLink>
                            <AppLink href='https://www.doordash.com/en-CA/store/seau-de-crabe-pointe-claire-2435917/' target='_blank' >
                                <AppLogo src={Doordash} alt="Doordash icon"/>
                            </AppLink>
                            <AppLink exact href='https://www.skipthedishes.com/seau-de-crabe-boulevard-des-sources' target='_blank' >
                                <AppLogo src={Skip} alt="Skip icon" />
                            </AppLink>
                            <AppLink
                                href='https://www.ubereats.com/ca/store/seau-de-crabe-pointe-claire/PRSFjU-KV1WHCL0h0QDzuA'
                                target='_blank'
                            >
                                <AppLogo src={Uber} alt="Uber eats icon" />
                            </AppLink>
                        </AppsLogoWrapper>
                </BranchWrapper>
            </BranchContainer>
        </FranchiseContainer>
    )
} 

export default PointeClairePage