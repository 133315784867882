export const INITIAL_STATE = {
  isMenuOpen: false,
  isLanguageOpen: false,
  isEnglish: true,
  navScrolled: true,
  hasSent: false,
  hasFranchiseSent: false,
  isTouchDevice: false,
  lastStep: false
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "TOGGLE_MENU_HIDDEN":
      return {
        ...state,
        isMenuOpen: false,
      }
    case "TOGGLE_NAV_MENU":
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen,
      }
    case "TOGGLE_LANGUAGES_NAV":
      return {
        ...state,
        isLanguageOpen: !state.isLanguageOpen,
      }
    case "CHANGE_LANGAGUE":
      return {
        ...state,
        isEnglish: action.payload,
      }
    case "IS_SCROLLING_TOP":
      return {
        ...state,
        navScrolled: true
      }
    case "IS_SCROLLING_BACK":
      return {
        ...state,
        navScrolled: false
      }
    case "HAS_SENT_SUCCESS":
      return {
        ...state,
        hasSent: !state.hasSent
      }
      case "HAS_FRANCHISE_SENT_SUCCESS":
        return {
          ...state,
          hasFranchiseSent: !state.hasFranchiseSent
        }
    case "IS_TOUCH_DEVICE":
      return {
        ...state,
        isTouchDevice: action.payload
      }
    case "IS_LAST_STEP":
      return {
        ...state,
        lastStep: action.payload
      }

    default:
      return state;
  }
};

export default reducer;
