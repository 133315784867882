import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const GridWrapper = styled.div`
    display: flex;
    justify-items: center;
    align-items: center;
    overflow: hidden;
    
    @media screen and (max-width: 980px) {
        flex-direction: column;
        overflow: visible;
    }
`

export const CardBox = styled.div`
    min-height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 2rem;
    text-transform: uppercase;
    padding: 100px 2rem;
    background-size: cover !important;
    background-position: center !important;
    transition: all .2s ease-in-out;
    cursor: default;

    &:hover {
        filter: brightness(0.9);
    }

    h3 {
        text-align: center;
        margin: 0;
    }
`

export const LinkButton = styled(Link)`
    color: #fff;
    text-decoration: none;
    background: transparent;
    border: 1px solid #fff;
    padding: 16px 4px;
    width: 100%;
    max-width: 125px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 1rem;
    transition: all .3s ease-in-out;

    &:hover {
        background: rgba(255,255,255,.2);
    }
`

