import { CardBox, GridWrapper, LinkButton } from "./Grids.style";
import Menu from '../../assests/the-menu.jpg'
import Franchaise from '../../assests/branch-counter.jpg'
import SocialMedia from '../../assests/social-media.jpg'
import Reservation from '../../assests/new-decoration-reservation.jpg'
import menuPDF from '../../assests/pdf/menu.pdf'
import I18n from './../../i18n'

const Grids = () => {

    const currentLangParam = localStorage.getItem('lang');

    return (
        <>
            <GridWrapper>
                <CardBox style={{ background: `url(${Menu})` }}>
                    <h3>
                        <I18n t="grid.hungry" />
                    </h3>
                    <LinkButton to={menuPDF} target="_blank" rel="noreferrer">
                        <I18n t="see-menu" />
                    </LinkButton>
                </CardBox>
                <CardBox style={{ background: `url(${Franchaise})` }}>
                    <h3>
                        <I18n t="grid.become-franchisee" />
                    </h3>
                    <LinkButton to={`/${currentLangParam}/franchise`}>
                        <I18n t="learn-more" />
                    </LinkButton>
                </CardBox>
                <CardBox style={{ background: `url(${SocialMedia})` }}>
                    <h3>
                        <I18n t="grid.share-moment" />
                    </h3>
                    <LinkButton to={`/${currentLangParam}/social-media`}>
                        <I18n t="see-more" />
                    </LinkButton>
                </CardBox>
            </GridWrapper>
            <GridWrapper>
                <CardBox style={{ background: `url(${Reservation})` }}>
                    <h3>
                        <I18n t="grid.our-story" />
                    </h3>
                    <LinkButton to={`/${currentLangParam}/our-story`}>
                        <I18n t="learn-more" />
                    </LinkButton>
                </CardBox>
            </GridWrapper>
        </>
    )

}

export default Grids;