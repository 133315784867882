export const toggleMenuHidden = () => ({
    type: "TOGGLE_MENU_HIDDEN",
});

export const toggleNavMenu = () => ({
    type: "TOGGLE_NAV_MENU",
});

export const toggleLanguagesNav = () => ({
    type: "TOGGLE_LANGUAGES_NAV",
});

export const changeLanguage = (boolean) => ({
    type: "CHANGE_LANGAGUE",
    payload: boolean
})

export const isScrollingTop = () => ({
    type: "IS_SCROLLING_TOP"
})

export const isScrollingBack = () => ({
    type: "IS_SCROLLING_BACK"
})

export const toggleHasSent = () => ({
    type: "HAS_SENT_SUCCESS"
})

export const toogleFranchiseHasSent = () => ({
    type: 'HAS_FRANCHISE_SENT_SUCCESS'
})

export const setTouchDevice = (boolean) => ({
    type: "IS_TOUCH_DEVICE",
    payload: boolean
})

export const setTheLastStep = (boolean) => ({
    type: "IS_LAST_STEP",
    payload: boolean
})