import styled, { keyframes } from "styled-components";
import Waves from '../../../assests/waves.gif'

export const ContactContainer = styled.div`
    background-color: #D0E1EE;
    `

export const ContactWrapper = styled.div`
    position: relative;
    /* background: url(${Waves}); */
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    min-height: 1000px;
`

export const ContactHeader = styled.div`
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 200px;
    z-index: 10;
    justify-content: center;
    align-items: center;

    & span {
        font-size: 24px;
        text-align: center;
        letter-spacing: 1px;
        font-weight: bold;
        color: #182435;
        text-shadow: 2px 2px #fff;
        text-transform: uppercase;
    }

    @media screen and (max-width:480px) {

        & span {
            font-size: 18px;
        }
    }
`

export const ContactTitle = styled.img`

    @media screen and (max-width:680px) {
        width: 250px;
    }
`

export const ContactGif = styled.img`
    width: 100%;
`

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 100px 2rem;
    align-items: center;
    background-color: #D0E1EE;

    h1 {
        color: #182435;
    }

    @media screen and (max-width:680px) {
        padding: 0 1rem 100px;

        h1 {
            font-size: 18px;
        }
    }

    @media screen and (max-width:420px) {
        h1 {
            font-size: 14px
        }
    }
`

export const Form = styled.form`
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width:780px) {
        width: 95%;
    }
`
export const InputWrapper = styled.h4`
    width: 100%;
    font-size: 21px;
    display: flex;
    flex-direction: row;
    letter-spacing: 2px;
    line-height: 40px;

    & span {
        width: 100%;
    }

    @media screen and (max-width:680px) {
        flex-direction: column;
    }
    @media screen and (max-width:480px) {
            font-size: 16px ;
        
    }
`

export const Label = styled.label`
    white-space: nowrap;

    @media screen and (max-width: 480px) {
        max-width: 85%;
        white-space: normal;
    }
`

export const Input = styled.input`
    width: 100%;
    margin: 0 10px 10px;
    padding: 0 10px;
    line-height: 40px;
    border: none;
    border-bottom: 1px solid #182435;
    color: #182435;
    background: none;
    font-size: 18px;
    outline: none;
    
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield !important;
    }

    @media screen and (max-width:480px) {
        font-size: 14px;
    }
`

export const Select = styled.select`
    width: 100%;
    border-radius: 5px;
    text-transform: uppercase;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #182435;
    padding: 5px;
    outline: none;
    letter-spacing: 2px;
`

export const Textarea = styled.textarea`
    width: 100%;
    height: 150px;
    background-image: linear-gradient(rgb(24, 36, 53) 2px, transparent 2px);
    background-size: 100% 40px;
    background-position: center bottom 5px;
    line-height: 2;
    padding: 0px;
    font-size: 18px;
    border: none;
    background-color: transparent;
    outline: none;
    margin: 10px;
`

export const WavesImg = styled.div`
    background: url(${Waves});
    width: 100%;
    min-height: 285px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    transform: translate(-50%, 15%);

    @media screen and (max-width:580px) {
        width: 95%;
    }
`

const Moves = keyframes`
    0% {
        top: 45%;
        left: 50%;
    }

    50% {
        top: 42%;
        left: 52%;
    }

    100% {
        top: 45%;
        left: 50%;
    }
`

export const BottleImg = styled.img`
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, 15%);
    z-index: 1;
    animation: ${Moves} 5s infinite;

    @media screen and (max-width:580px) {
        width: 200px;
    }
`