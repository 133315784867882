import styled from 'styled-components'
import HeroBg from '../../assests/hero-desk.jpg'
import HeroBgMob from '../../assests/hero.jpg'

export const HeroContainer = styled.div`
    width: 100%;
    background: url(${HeroBg});
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    height: 70vh;

    ${({ isTouchDevice }) => isTouchDevice && `
        background-attachment: unset !important;
    `}

    @media screen and (max-width: 680px) {
        background: url(${HeroBgMob});
        height: 450px;
    }

    @media screen and (max-width: 480px) {
        height: 350px;
    }
`