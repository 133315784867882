import { Address, BranchContainer, BranchLabel, BranchWrapper, BrunchPhoto, City, FranchiseContainer, Map, Text } from "../../Franchise/Franchise.style"
import { Helmet } from "react-helmet";
import { AppLink, AppLogo, AppsLogoWrapper } from "../../../OrderApp/Order.style";
import Uber from './../../../../assests/uber.png'
import Doordash from './../../../../assests/doordash.jpg'
import SeauDeCrabe from './../../../../assests/cluster.jpg'
import Montreal from './../../../../assests/montreal-title.png'
import bruncheBackground from './../../../../assests/montreal-branch.jpg';
import I18n from "../../../../i18n";
import Navbar from "../../../Navbar/Navbar.component";

const MontrealPage = () => {

    const iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2795.8066886010847!2d-73.57877758710104!3d45.51396992999443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc91b68b63853e7%3A0xdae025d17299311e!2sSeau%20de%20Crabe!5e0!3m2!1sen!2sca!4v1709005498946!5m2!1sen!2sca" width="400" height="300" style="border:0; width:100%" allowfullscreen="" loading="lazy"></iframe>'

    return (
        <FranchiseContainer>
            <Helmet>
                <title>Seau de Crabe - Montréal</title>
                <meta name="title" content="Seau de Crabe - Montréal Downtown" />
                <link rel="canonical" href="https://www.seaudecrabe.ca/location/montréal-downtown" />
                <meta name="description" content="Seau de Crabe offers delicious and fresh seafood in Montréal, QC" />
                <meta name="keywords" content="Seafood restaurant, Seafood, Restaurant, Ooh, Crabe, crab, fish, shrimp, crevette, lobster, homard, Mussels, Moules, Downtown, Montréal, Qeubec" />
                <meta property="og:description" content="Seau de Crabe offers delicious and fresh seafood in Montréal, QC" />
                <meta property="og:title" content="Seau De Crabe - Montreal Dowtown" />
                <meta property="og:url" content="https://www.seaudecrabe.ca/" />
                <meta name="author" content="Seau de Crabe" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Navbar />
            <BrunchPhoto style={{ 'background': `url(${bruncheBackground})` }}>
                <BranchLabel src={Montreal}></BranchLabel>
            </BrunchPhoto>
            <Map class="map" dangerouslySetInnerHTML={{ __html: iframe }} />
            <BranchContainer>
                <BranchWrapper>
                    <City><I18n t='address' />:</City>
                    <Address href="https://maps.app.goo.gl/f5gtF56DFCQzb3pc6" target="_blank">3751 Rue Saint-Urbain, Montréal, QC H2W 1T4</Address>
                    <br />
                    <City>Contact: </City>
                    <Address href="tel:+15146401111">+ 1 (514) 640-1111</Address>
                    <br />
                    <City><I18n t='hours' />:</City>
                    <Text><I18n t='weekdays.monday' /> 12 p.m. - 10 p.m.</Text>
                    <Text><I18n t='weekdays.tuesday' /> 12 p.m. - 10 p.m.</Text>
                    <Text><I18n t='weekdays.wednesday' /> 12 p.m. - 10 p.m.</Text>
                    <Text><I18n t='weekdays.thursday' /> 12 p.m. - 10 p.m.</Text>
                    <Text><I18n t='weekdays.friday' /> 12 p.m. - 10 p.m.</Text>
                    <Text><I18n t='weekdays.saturday' /> 12 p.m. - 1 a.m.</Text>
                    <Text><I18n t='weekdays.sunday' /> 12 p.m. - 1 a.m.</Text>
                    <City><I18n t='order-online' /></City>
                    <AppsLogoWrapper>
                        <AppLink href='https://seaudecrabe.order-online.ai/#/' target='_blank' >
                            <AppLogo src={SeauDeCrabe} alt="SeauDeCrabe cluster app logo" />
                        </AppLink>
                        <AppLink href='https://www.doordash.com/store/seau-de-crabe-downtown-montr%C3%A9al-25615010/' target='_blank' >
                            <AppLogo src={Doordash} alt="Doordash icon" />
                        </AppLink>
                        <AppLink
                            href='https://www.ubereats.com/ca-fr/store/seau-de-crabe-downtown/VPl89a5ZV2K7X3ucksY0qA'
                            target='_blank'
                        >
                            <AppLogo src={Uber} alt="Uber eats icon" />
                        </AppLink>
                    </AppsLogoWrapper>
                </BranchWrapper>
            </BranchContainer>
        </FranchiseContainer>
    )
}

export default MontrealPage;