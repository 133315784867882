import styled from 'styled-components'
import background from '../../../assests/seau-de-crabe-main-bg.jpg'
import backgroundMob from '../../../assests/seau-de-crabe-main-bg-mob.jpg'

export const SocialMediaContainer = styled.div`
    height: calc(100vh - 220px);
    background: url(${background});
    background-size: cover !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
    text-align: center;

    @media screen and (max-width: 1200px) {
        background-size: contain !important;
    }

    @media screen and (max-height: 1200px) {
        height: 100%;
    }

    @media screen and (max-width: 680px) {
        background: url(${backgroundMob});
    }

    & > .alice-carousel {
        margin: 2rem auto;
    }
`

export const SocialMediaTitleImg = styled.img`
    
    @media screen and (max-width: 680px) {
        width: 300px;
    }
`

export const HeadTitle = styled.h2`
    font-size: 36px;
    color: #312f2b;
    margin-bottom: 0;
    cursor: default;

    @media screen and (max-width: 680px) {
        font-size: 24px;
        padding: 0 2rem;
    }
`

export const HashTag = styled.h3`
    font-size: 30px;
    color: #312f2b;
    font-weight: 700;
    margin-top: 0;
    cursor: default;

    @media screen and (max-width: 680px) {
        font-size: 22px;
        padding: 0 2rem;
    }
`

export const InstaPost = styled.img`
    width: 300px;
    height: 300px;
    filter: brightness(.8);
    transition: all .3s ease-in-out;

    &:hover {
        filter: brightness(1);
    }

    @media screen and (max-width: 480px) {
        filter: brightness(1);
    }


`