import { InstaPost } from './Social-media.style';
import Post1 from './../../../assests/social-media/insta_1.jpg';
import Post2 from './../../../assests/social-media/insta_2.jpg';
import Post3 from './../../../assests/social-media/insta_3.jpg';
import Post4 from './../../../assests/social-media/insta_4.jpg';
import Post5 from './../../../assests/social-media/insta_5.jpg';
import Post6 from './../../../assests/social-media/insta_6.jpg';
import Post7 from './../../../assests/social-media/insta_7.jpg';
import Post8 from './../../../assests/social-media/insta_8.jpg';
import Post9 from './../../../assests/social-media/insta_9.jpg';

export const _items = [
    <a target='_blank' href="https://www.instagram.com/p/Cii-U2RAMKK/?hl=en">
        <InstaPost src={Post1} alt="insta post 1" />
    </a>,
    <a target='_blank' href="https://www.instagram.com/p/Cf49Jt_gW1k/?hl=en">
        <InstaPost src={Post2} alt="insta post 2" />
    </a>,
    <a target='_blank' href="https://www.instagram.com/p/Cgx2dkkA-Qr/?hl=en">
        <InstaPost src={Post3} alt="insta post 3" />
    </a>,
    <a target='_blank' href="https://www.instagram.com/p/CcRHNMNrPqI/?hl=en">
        <InstaPost src={Post4} alt="insta post 4" />
    </a>,
    <a target='_blank' href="https://www.instagram.com/p/CehcPd4ASXw/?hl=en">
        <InstaPost src={Post5} alt="insta post 5" />
    </a>,
    <a target='_blank' href="https://www.instagram.com/p/CbWHk2DAFdc/?hl=en">
        <InstaPost src={Post9} alt="insta post 6" />
    </a>,
    <a target='_blank' href="https://www.instagram.com/p/CZAtumsALAf/?hl=en">
        <InstaPost src={Post7} alt="insta post 7" />
    </a>,
    <a target='_blank' href="https://www.instagram.com/p/Ci1CppGg-CZ/?hl=en">
        <InstaPost src={Post8} alt="insta post 8" />
    </a>,
    <a target='_blank' href="https://www.instagram.com/p/CcePEUqDLiX/?hl=en">
        <InstaPost src={Post6} alt="insta post 9" />
    </a>
];