import styled from 'styled-components'
import Wallpaper from '../../assests/seau.jpg'
import GrungeLine from '../../assests/grunge-line-new.png'

export const Lamp = styled.img`
    width: 100%;
`

export const OrderContainer = styled.div`
    height: 100%;
    background-image: url(${Wallpaper});
    color: #fff;
    padding: 5rem 0;
    margin-bottom: 100px;
    position: relative;
    text-align: center;

 &::before {
    content: '';
    background: url(${GrungeLine});
    background-size: contain;
    height: 80px;
    width: 100%;
    position: absolute;
    top: -78px;
    left: 0;
    z-index: 3;

    @media only screen and (max-width: 680px ) {
        height: 40px;
        top: -38px
    }
  }

  &::after {
    content: '';
    background: url(${GrungeLine});
    background-size: contain;
    height: 80px;
    width: 100%;
    position: absolute;
    bottom: -78px;
    left: 0;
    z-index: 3;
    transform: rotate(180deg);

    @media only screen and (max-width: 680px ) {
        height: 40px;
        bottom: -38px
    }
  }

  @media only screen and (max-width: 420px) {
        margin-bottom: 0;
    }
`

export const OrderTitle = styled.div`
    text-align: center;
    font-size: 32px;
    padding: 3rem 0;
    text-transform: uppercase;
`

export const AppsLogoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    @media only screen and (max-width: 1000px ) {
        flex-direction: column;
    };
`

export const AppLink = styled.a`
`

export const AppLogo = styled.img`
    width: 200px;
    height: 100px;
    border-radius: 20px;
    cursor: pointer;
    filter: brightness(80%);
    transition: filter .3s ease-in-out;

    @media only screen and (max-width: 1000px ) {
        margin: 15px;
    }

    @media only screen and (max-width: 680px ) {
        width: 200px;
        height: 100px;
    }

    &:hover, &:active {
        filter: brightness(110%);
    }
`

export const SCAppLogo = styled.img`
    width: 450px;
    height: 225px;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 5rem;
    filter: brightness(80%);
    transition: filter .3s ease-in-out;

    @media only screen and (max-width: 1000px ) {
        margin: 15px;
    }

    @media only screen and (max-width: 680px ) {
        width: 200px;
        height: 100px;
    }

    &:hover, &:active {
        filter: brightness(110%);
    }
`

export const StepHead = styled.img`
    width: 350px;
    margin-bottom: 5rem;

    @media screen and (max-width:480px) {
        width: 250px;
    }
`

export const OrderBtn = styled.button`
    display: block;
    background-color: red;
    border: 2px solid red;
    max-width: 300px;
    text-align: center;
    font-size: 18px;
    padding: .5rem;
    border-radius: 10px;
    filter: brightness(90%);
    z-index: 999999;
    position: fixed;
    right: 5vw;
    bottom: 50px;
    display: block;
    transition: all .4s ease-in-out;

    ${({ lastStep }) => lastStep && `
        position: absolute;
    `}

    &:hover {
        filter: brightness(110%);
    }
`

export const OrderLink = styled.a`
    color: white;
    border-radius: 10px;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    display: flex;
    align-items: center;

    &:hover {
        color: white;
        text-decoration: none;
    }

    img {
        width: 35px;
        margin-right: 8px;

        @media screen and (max-width: 480px) {
            width: 30px;
        }
    }

    p {
        margin: 0;
        font-size: 12px;

        @media screen and (max-width: 480px) {
            font-size: 10px;
        }
    }
`